<template>
  <b-container class="page-container">
    <div class="flex-row align-items-center">
      <b-row class="justify-content-center">
          <b-col md="6" sm="12">
            <b-card no-body class="mx-4">          
              <b-card-body class="p-4">
                <b-form @submit.prevent>
                  <h5>¿Tienes problemas para iniciar sesión?</h5>
                  <p>Ingresa tu correo electrónico y te enviaremos un enlace para recuperar tu contraseña.</p>
                  <br>
                  <b-row v-if="error">
                    <b-col cols="12">
                      <div class="alert alert-danger" role="alert" v-for="error_message in errorMessages">
                          {{error_message}}
                      </div> 
                    </b-col>
                  </b-row>
                  <b-row v-if="success">
                    <b-col cols="12">
                      <div class="alert alert-success" role="alert" v-for="success_message in successMessages">
                          {{success_message}}
                      </div> 
                    </b-col>
                  </b-row>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>@</b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="text" class="form-control" placeholder="Email" autocomplete="email" v-model="email"/>
                  </b-input-group>         
                  <b-button class="btn btn-md p-bg-light-text btn-first-margin"  block @click.prevent="recoveryLink">Enviar correo de recuperación</b-button>
                </b-form>
              </b-card-body>
              <b-card-footer class="p-4">
                <b-row>
                  <b-col cols="12" class="text-center">
                    <!-- <button type="button" block class="btn btn-link text-link-color" to="/pages/login">  -->
                    <button type="button" class="btn btn-link text-link-color" @click.prevent="goTo('login')">
                      Volver a inicio de sesión
                    </button>                    
                  </b-col>
                </b-row>
              </b-card-footer>
            </b-card>
          </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
export default {
  name: 'Register',
  data: function() {
      return {
          email: '',
          error: false,  
          success: false,
          errorMessages: [], 
          successMessages: []
      }
  },
  methods: {
    recoveryLink: function (){
        var auth = this.$firebase.auth();
        auth.languageCode = 'es'
        auth.sendPasswordResetEmail(this.email).then((recovery) => {
          this.error = false   
          this.success = true
          this.successMessages = ['Hemos enviado las instrucciones a tu correo electrónico']
        }).catch((e) => {
          this.error = true
          this.success = false
          this.errorMessages = ['No hemos podido restablecer tu contraseña']
        })
    },
    goTo(page){
      if(page == 'login'){
        console.log('go to login')
        this.$router.replace('/pages/login')
      }
    },
  }
}
</script>
<style scoped>
  .page-container{
    margin-top: 2rem;
  }
</style>
